import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, Outlet, useLocation } from 'react-router-dom';
import CognideXLogo from '../../assets/logo/cognidex-logo.svg';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faXTwitter, faTelegramPlane, faMedium } from '@fortawesome/free-brands-svg-icons';

/* Keyframes for slide-in animations */
const slideInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideInFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

/* Keyframes for slide-out animations */
const slideOutToLeft = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const slideOutToRight = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const slideDownFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background: transparent;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
`;

const DesktopNav = styled.nav`
  display: none;
  top: 0;
  left: 0;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  background: transparent;
  padding: 1rem 5vw;
  z-index: 100;

  @media (min-width: 1150px) {
    display: flex;
  }

  .logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease-in-out;
    transform: ${({ hidden }) => (hidden ? 'translateY(-100px)' : 'translateY(0)')};

    img {
      width: 50px;
      height: 50px;
    }

    p {
      font-size: 24px;
      font-weight: bold;
      margin-left: 10px;
      color: white;
      
    }
  }

  .nav-links-wrapper {
    position: relative;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7); /* Background for nav-links */
    padding: 0.5rem 1rem;
    border-radius: 8px;
  }

  .nav-links {
    display: flex;
    gap: 50px;
    position: relative;
  }

  .nav-link {
    position: relative;
    text-decoration: none;
    font-size: 20px;
    color: white;
    font-weight: bold;
    padding: 0.5rem 0;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: var(--primary-color);
    }
  }
`;

/* Dropdown menu styling with animation */
const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: calc(100%);
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0 0 8px 8px;
  width: 150px;
  padding: 0 0 0.5rem 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  animation: ${slideDownFadeIn} 0.3s ease forwards;

  .dropdown-item {
    color: white;
    font-size: 18px;
    text-decoration: none;
    display: block;
    padding: 0.5rem 0;
    text-align: center;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary-color);
    }
  }

  &:hover {
    display: block;
  }
`;

/* Update the nav link container to include the dropdown */
const NavItem = styled.div`
  position: relative;
  flex: 1;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover ${DropdownMenu} {
    display: block;
  }
`;

const HamburgerMenu = styled.div`
  position: fixed;
  top: 5vw;
  left: 5vw;
  display: flex;
  align-items: center;
  background: transparent;
  width: 60px;
  height: 60px;
  gap: 10px;
  z-index: 101;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  @media (min-width: 1150px) {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

   @media (max-width: 1000px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    top: 10vh;
    left: 5vw;
  }

  .explore-text {
    font-size: 1.2rem;
    color: white;
    opacity: 0;
    font-weight: 800;
    transform: translateX(-50%);
    white-space: nowrap;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    @media (min-width: 1000px) {
      font-size: 1.1rem;
    }
  }

  .menu-text {
    font-size: 1.2rem;
    color: white;
    font-weight: 800;
    transform: translateX(-140%) scale(1.2);
    white-space: nowrap;
    opacity: 1;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, scale 0.3s ease-in-out;
  
    @media (min-width: 1000px) {
      font-size: 1.1rem;
    }
  }

  &:hover {
    transform: rotate(-45deg) scale(1.3);

    img {
      filter: drop-shadow(5px 5px 10px rgba(255, 255, 255, 0.8));
    }

    .explore-text {
      opacity: 1;
      transform: translateX(30%) rotate(45deg) translateY(270%) scale(1);
    }

    .menu-text {
      opacity: 0;
      transform: translateX(-160%) rotate(45deg) translateY(30%) scale(1);
    }
  }
`;

const NavigationComponent = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  font-size: 22px;
  font-family: 'Modernist', sans-serif;
  color: black;
  background: white;
  text-decoration: none;
  font-weight: bold;
  position: relative;
  opacity: ${(props) => (props.isClosing ? 1 : 0)};
  animation: ${(props) =>
    props.isClosing
      ? props.animationType === 'left'
        ? slideOutToLeft
        : slideOutToRight
      : props.animationType === 'left'
        ? slideInFromLeft
        : slideInFromRight}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: ${(props) => props.delay}s;

  p {
    color: black;
    font-size: 4vw;
    font-weight: bold;
    margin: 0;
  }

  @media (max-width: 768px) {
    p {
      font-size: 2rem;
    }
  }

  &:before,
  &:after {
    content: "";
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    font-size: 5vw;
  }

  &:before {
    content: "{";
    margin-right: 10px;
  }

  &:after {
    content: "}";
    margin-left: 10px;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    transform: scale(1);
  }
`;

const LogoContainer = styled(Link)`
  position: absolute;
  top: 5vw;
  left: 5vw;
  display: ${(props) => (props.$isClosing ? 'none' : 'flex')};
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: auto;
  height: 80vh;
  gap: 8px;
  text-decoration: none;
  z-index: 100;

  .logo-container {
    display: flex;
    flex-direction: row;
    width: auto;
    height: 60px;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 100%;
    height: 100%;
    filter: invert(1);
  }

  p {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Modernist', sans-serif;
    color: black;
    margin: 0;
  }

  @media (max-width: 768px) {
    top: 10vh;
    left: 5vw;
    height: 90vh;

    p {
      display: none;
    }
  }
`;

const FollowUsContainer = styled.div`
  display: ${(props) => (props.$isClosing ? 'none' : 'flex')};
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 50px;
  height: 100%;
  text-decoration: none;
  z-index: 100;

  img {
    width: 100%;
    height: 100%;
    filter: invert(1);
  }

  a {
    text-decoration: none;
    color: black;
    transition: color 0.3s ease-in-out;
    font-size: 18px;

    &:hover {
      color: var(--primary-color);
    }
  }

  p {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Modernist', sans-serif;
    color: black;
    margin: 0;
  }
`

const CloseContainer = styled.div`
  position: absolute;
  top: 5vw;
  right: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
  transition: all 0.3s ease-in-out;

  svg {
    color: black;
  }

  &:hover {
    transform: scale(1.1) rotate(90deg);
  }

  // onclick scale smaller
  &:active {
    transform: scale(0.9) rotate(90deg);
  }

  @media (max-width: 768px) {
    top: 10vh;
    right: 5vw;
    width: 50px;
    height: 50px;
  }
`;

const NavigationComponentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const NavigationBar = () => {
  const { user, openSigninModal } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  const [isNavHidden, setIsNavHidden] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsNavHidden(false);
      } else {
        setIsNavHidden(true);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const navLinks = [
    { path: '/datapool?role=contribute', label: 'Contribute Data' },
    { path: '/D-GPT', label: 'Find Data' },
    { path: '/I-GPT', label: 'Generate Insight' },
    {
      path: user ? '/account' : '',
      label: user ? 'Account' : 'Sign In/Up'
    },
  ];

  const dataPoolLinks = [
    { path: '/datapool/contribute/LinkedIn', label: 'LinkedIn' },
    { path: '/datapool/contribute/YouTube', label: 'Youtube' },
    { path: '/datapool/contribute/TikTok', label: 'TikTok' },
  ]

  const findDataLinks = [
    { path: '/D-GPT', label: 'Data GPT' },
    { path: '/datapool?role=buy', label: 'Data Pool' },
  ]

  const setActiveLinkPath = (path, label) => {
    if (label === 'Sign In/Up') {
      openSigninModal();
      return;
    }
    setActiveLink(path);
  }

  // Handle opening the modal
  const openModal = () => {
    setIsModalVisible(true);
  };

  // Handle closing the modal with animation
  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalVisible(false);
      setIsClosing(false);
    }, 900); // Adjusted to match total animation time
  };

  const toggleModal = () => {
    if (isModalVisible) closeModal();
    else openModal();
  };

  const toggleModalAndSignin = () => {
    openSigninModal();
    toggleModal();
  };

  return (
    <AppContainer>
      <HamburgerMenu onClick={toggleModal}>
        <img src={CognideXLogo} alt="CognideX Logo" />
        <p className="explore-text">Explore</p>
        <p className="menu-text">Menu</p>
      </HamburgerMenu>

      {/* Regular desktop navigation bar for larger screens */}
      <DesktopNav hidden={isNavHidden}>
        <Link className="logo-container" to="/" onClick={() => setActiveLink('/')}>
          <img src={CognideXLogo} alt="CognideX Logo" />
          <p>CognideX</p>
        </Link>
        <div className="nav-links-wrapper">
          <div className="nav-links">
            {navLinks.map((link, index) => (
              <NavItem key={index}>
                <Link
                  to={link.path}
                  className="nav-link"
                  onClick={() => setActiveLinkPath(link.path, link.label)}
                  style={{
                    color: activeLink === link.path ? 'var(--primary-color)' : 'white',
                  }}
                >
                  {link.label}
                </Link>
                {/* Add DropdownMenu only for DataPool */}
                {link.label === 'Contribute Data' && (
                  <DropdownMenu>
                    {dataPoolLinks.map((dataPoolLink, index) => (
                      <Link
                        key={index}
                        to={dataPoolLink.path}
                        className="dropdown-item"
                        onClick={() => setActiveLink(dataPoolLink.path)}
                        style={{
                          color: activeLink === dataPoolLink.path ? 'var(--primary-color)' : 'white',
                        }}
                      >
                        {dataPoolLink.label}
                      </Link>
                    ))}
                  </DropdownMenu>
                )}
                {/* Add DropdownMenu only for Find Data */}
                {link.label === 'Find Data' && (
                  <DropdownMenu>
                    {findDataLinks.map((findDataLink, index) => (
                      <Link
                        key={index}
                        to={findDataLink.path}
                        className="dropdown-item"
                        onClick={() => setActiveLink(findDataLink.path)}
                        style={{
                          color: activeLink === findDataLink.path ? 'var(--primary-color)' : 'white',
                        }}
                      >
                        {findDataLink.label}
                      </Link>
                    ))}
                  </DropdownMenu>
                )}
              </NavItem>
            ))}
          </div>
        </div>
      </DesktopNav>

      {isModalVisible && (
        <Container>
          <LogoContainer to="/" onClick={toggleModal} $isClosing={isClosing}>
            <div className='logo-container'>
              <img src={CognideXLogo} alt="CognideX Logo" />
              <p>CognideX</p>
            </div>
            <FollowUsContainer $isClosing={isClosing}>
              <a href="https://x.com/0xcognidexai" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
              <a href="https://t.me/CognideX" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTelegramPlane} size="2x" />
              </a>
              <a href="https://medium.com/@0xcognidexAI" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faMedium} size="2x" />
              </a>
            </FollowUsContainer>
          </LogoContainer>
          <CloseContainer onClick={toggleModal}>
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </CloseContainer>
          <NavigationComponentContainer>
            <NavigationComponent
              to="/datapool?role=contribute"
              onClick={toggleModal}
              animationType="left"
              delay={0.4}
              isClosing={isClosing}
            >
              <p>Contribute Data</p>
            </NavigationComponent>
            <NavigationComponent
              to="/D-GPT"
              onClick={toggleModal}
              animationType="right"
              delay={0.2}
              isClosing={isClosing}
            >
              <p>Find Data</p>
            </NavigationComponent>
            <NavigationComponent
              to="/I-GPT"
              onClick={toggleModal}
              animationType="left"
              delay={0}
              isClosing={isClosing}
            >
              <p>Generate Insight</p>
            </NavigationComponent>
            <NavigationComponent
              to={user ? '/account' : ''}
              onClick={user ? toggleModal : toggleModalAndSignin}
              animationType="right"
              delay={0.4}
              isClosing={isClosing}
            >
              <p>
                {user ? 'Account' : 'Sign In/Up'}
              </p>
            </NavigationComponent>
          </NavigationComponentContainer>
        </Container>
      )}
      <Outlet />
    </AppContainer>
  );
};

export default NavigationBar;
