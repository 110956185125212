import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useAuth } from '../../context/AuthContext';

import MainInput from '../inputs/MainInput';
import MainButtonInvert from '../buttons/MainButtonInvert';
import { useError } from '../../context/ErrorContext';
import { useSuccess } from '../../context/SuccessContext';

import { resetPassword, confirmResetPassword } from 'aws-amplify/auth';

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const scaleIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const scaleOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Styled components with animations
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  z-index: 101;

  animation: ${(props) =>
        props.animationType === 'in' ? fadeIn : fadeOut}
    0.3s ease-in-out forwards;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 400px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 0 10px var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 10px 10px 10px 15px;
  gap: 20px;

  animation: ${(props) =>
        props.animationType === 'in' ? scaleIn : scaleOut}
    0.3s ease-in-out forwards;
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h3 {
        font-size: 30px;
        font-family: 'Modernist', sans-serif;
        color: black;
        margin: 0;
    }

    p {
        font-size: 20px;
        font-weight: 600;
        font-family: 'Modernist', sans-serif;
        color: black;
        margin: 0;
    }


    span {
        font-size: 20px;
        font-weight: 600;
        font-family: 'Modernist', sans-serif;
        color: black;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        text-decoration: underline;

        &:hover {
            color: var(--primary-color);
        }
    }
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 10px);
  gap: 20px;
  padding-right: 10px;
`;

const ModalEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: calc(100% - 10px);
  gap: 20px;
  padding-right: 10px;
`;

const ForgotPasswordModal = () => {
    const { forgotPasswordModalOpen, closeForgotPasswordModal, openSigninModal } = useAuth();
    const [step, setStep] = useState('request'); // Steps: 'request', 'confirm'
    const [userInfo, setUserInfo] = useState({ email: '', password: '' });
    const [verificationCode, setVerificationCode] = useState('');
    const [animationType, setAnimationType] = useState('in');
    const [isVisible, setIsVisible] = useState(false);
    const { openErrorModal } = useError();
    const { openSuccessModal } = useSuccess();

    useEffect(() => {
        if (forgotPasswordModalOpen) {
            setIsVisible(true);
            setAnimationType('in');
        } else if (!forgotPasswordModalOpen && isVisible) {
            setAnimationType('out');
            setTimeout(() => {
                setIsVisible(false);
            }, 300);
        }
    }, [forgotPasswordModalOpen, isVisible]);

    if (!isVisible) return null;

    const handleClose = () => {
        setAnimationType('out');
        setTimeout(() => {
            closeForgotPasswordModal();
        }, 300);
    };

    const handleRequestReset = async () => {
        try {
            await resetPassword({
                username: userInfo.email,
            })
            setStep('confirm');
            openSuccessModal({
                successHeader: 'Verification Code Sent',
                successMessage: 'A verification code has been sent to your email.',
            });
        } catch (e) {
            openErrorModal({
                errorHeader: 'Error Requesting Reset',
                errorMessage: e.message,
            });
        }
    };

    const handleConfirmReset = async () => {
        try {
            await confirmResetPassword({
                username: userInfo.email,
                confirmationCode: verificationCode,
                newPassword: userInfo.password,
            })
            setStep('request');
            closeForgotPasswordModal();
            openSuccessModal({
                successHeader: 'Password Reset Successful',
                successMessage: 'You can now log in with your new password.',
            });
        } catch (e) {
            openErrorModal({
                errorHeader: 'Error Confirming Reset',
                errorMessage: e.message,
            });
        }
    };

    const handleSignIn = () => {
        handleClose();
        openSigninModal();
    }

    return (
        <Background animationType={animationType}>
            <Modal animationType={animationType}>
                <ModalHeader>
                    <h3>Forgot Password</h3>
                    <CloseButton onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M18.3 5.71a1 1 0 0 0-1.42 0L12 10.59 7.12 5.71A1 1 0 0 0 5.7 7.12L10.59 12l-4.88 4.88a1 1 0 1 0 1.42 1.42L12 13.41l4.88 4.88a1 1 0 0 0 1.42-1.42L13.41 12l4.88-4.88a1 1 0 0 0 0-1.41z" />
                        </svg>
                    </CloseButton>
                </ModalHeader>
                {step === 'request' && (
                    <>
                        <ModalHeader>
                            <p>Remember your password? <span onClick={handleSignIn}>Sign In</span></p>
                        </ModalHeader>
                        <ModalBody>
                            <MainInput
                                label="Email"
                                type="email"
                                value={userInfo.email}
                                onChange={(e) =>
                                    setUserInfo({ ...userInfo, email: e.target.value })
                                }
                            />
                        </ModalBody>

                        <ModalEnd>
                            <MainButtonInvert
                                header="Request Reset"
                                subheader="Send Verification Code"
                                onClick={handleRequestReset}
                            />
                        </ModalEnd>
                    </>
                )}
                {step === 'confirm' && (
                    <>
                        <ModalHeader>
                            <p>Verification code sent to your email. <br /> <span onClick={() => setStep('request')}>Resend Code</span></p>
                        </ModalHeader>
                        <ModalBody>
                            <MainInput
                                label="Verification Code"
                                type="number"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                            <MainInput
                                label="New Password"
                                type="password"
                                value={userInfo.password}
                                onChange={(e) =>
                                    setUserInfo({ ...userInfo, password: e.target.value })
                                }
                            />
                        </ModalBody>
                        <ModalEnd>
                            <MainButtonInvert
                                header="Reset Password"
                                subheader="Confirm Reset"
                                onClick={handleConfirmReset}
                            />
                        </ModalEnd>
                    </>
                )}
            </Modal>
        </Background>
    );
};

export default ForgotPasswordModal;
