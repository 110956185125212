const getInstructionsContent = (platform) => {
    const instructions = {
        linkedin: [
            <>Click the <strong>LinkedIn Me Icon</strong> at the top of your LinkedIn homepage.</>,
            <>Select <a href="https://www.linkedin.com/mypreferences/d/categories/privacy" target="_blank" rel="noreferrer">Settings & Privacy</a> from the dropdown.</>,
            <>Click <a href="https://www.linkedin.com/mypreferences/d/categories/privacy" target="_blank" rel="noreferrer">Data Privacy</a> on the left rail.</>,
            <>Under the 'How LinkedIn uses your data' section, click <a href="https://www.linkedin.com/mypreferences/d/download-my-data" target="_blank" rel="noreferrer">Get a copy of your data</a>.</>,
            "Select the data that you're looking for and Request archive.",
            <>More Detailed Instructions can be found <a href='https://docs.cognidex.ai/Learn/contribute-data-pool#linkedin' target='_blank' rel='noreferrer'>here</a>.</>
        ],
        tiktok: [
            <>In the TikTok app, tap <strong>Profile</strong> at the bottom.</>,
            <>Tap the <strong>Menu ☰</strong> button at the top, then tap <strong>Settings and privacy</strong>.</>,
            <>Tap <strong>Account</strong>.</>,
            <>Tap <strong>Download your data</strong>.</>,
            <>Choose which information you want to include in your file to download and select a file format.</>,
            <>Tap <strong>Request data</strong>.</>,
            "After you submit your request, they'll create a file of your data that you can download from the download data tab. TikTok will notify you in the app when it's ready to download. Please note that it may take a few days to prepare the file.",
            <strong>To download your TikTok data:</strong>,
            <>In the TikTok app, tap <strong>Profile</strong> at the bottom.</>,
            <>Tap the <strong>Menu ☰</strong> button at the top, then tap <strong>Settings and privacy</strong>.</>,
            <>Tap <strong>Account</strong>.</>,
            <>Tap <strong>Download your data</strong>.</>,
            <>Tap <strong>Download data</strong> to see the status of your request, and tap <strong>Download</strong> if it's ready. Your file will be available for up to 4 days.</>
        ],
        youtube: [
            <>Log into your Google Account and go to the <a href="https://takeout.google.com/" target="_blank" rel="noreferrer">Google Takeout page</a>.</>,
            "Google products you use and store data in are automatically selected. Uncheck any products you don’t want to include in your archive.",
            "For YouTube, you may have the option to customize your data selection (e.g., only certain playlists or videos).",
            <>Click <strong>Next step</strong>.</>,
            <>Choose a delivery method: <strong>Email</strong>, <strong>Drive</strong>, <strong>Dropbox</strong>, <strong>Microsoft OneDrive</strong>, or <strong>Box</strong>.</>,
            <>Select export type: <strong>One-time archive</strong> or <strong>Scheduled exports</strong>.</>,
            <>Choose file type: <strong>Zip</strong> or <strong>Tgz</strong>.</>,
            "Set a maximum archive size. If your data exceeds this size, multiple archives will be created.",
            "Google will email you a link to download your archive. Depending on the data volume, this process may take minutes or days.",
            "Your archive link will be valid for a limited time."
        ]
    };

    if (!instructions[platform]) {
        return {
            header: "Instructions",
            message: <p>No specific instructions found for this platform.</p>
        };
    }

    return {
        header: (
            <>
                How to Retrieve <br /> Your {platform.charAt(0).toUpperCase() + platform.slice(1)} Data
            </>
        ),
        message: (
            <ol>
                {instructions[platform].map((step, index) => (
                    <li key={index}>{step}</li>
                ))}
            </ol>
        )
    };
};

export default getInstructionsContent;
